import {
  Image as ImageBase,
  ImageCropped as ImageCroppedBase,
  ImageCover as ImageCoverBase
} from '@kaliber/sanity-image'
import { useClientConfig } from '/machinery/ClientConfig'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import styles from './Image.css'

export function Image({ image, imgProps = {}, sizes = undefined, layoutClassName = undefined }) {
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const { sanity } = useClientConfig()

  const loading = isInViewport ? 'eager' : 'lazy'

  return (
    <div ref={isInViewportRef} className={cx(styles.component, layoutClassName)}>
      <ImageBase
        sanityConfig={sanity.clients.readOnly}
        imgProps={{ ...imgProps, alt: image?.alt, loading }}
        {...{ image, sizes }}
      />
    </div>
  )
}

export function ImageCropped({ image, aspectRatio, imgProps = {}, sizes = undefined, layoutClassName = undefined }) {
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const { sanity } = useClientConfig()

  const loading = isInViewport ? 'eager' : 'lazy'

  return (
    <div ref={isInViewportRef} className={layoutClassName}>
      <ImageCroppedBase
        sanityConfig={sanity.clients.readOnly}
        imgProps={{ ...imgProps, alt: image?.alt, loading }}
        {...{ image, sizes, aspectRatio }}
      />
    </div>
  )
}

export function ImageCover({ image, aspectRatio, imgProps = {}, sizes = undefined, layoutClassName = undefined }) {
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const { sanity } = useClientConfig()

  const loading = isInViewport ? 'eager' : 'lazy'

  return (
    <div ref={isInViewportRef} className={cx(styles.componentCover, layoutClassName)}>
      <ImageCoverBase
        sanityConfig={sanity.clients.readOnly}
        imgProps={{ ...imgProps, alt: image?.alt, loading }}
        {...{ image, sizes, aspectRatio }}
      />
    </div>
  )
}

export function ImageCoverDynamicScale({ image, aspectRatio, imgProps = {}, sizes = undefined, layoutClassName = undefined }) {
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const { sanity } = useClientConfig()

  const loading = isInViewport ? 'eager' : 'lazy'

  return (
    <div
      ref={isInViewportRef}
      className={cx(
        styles.componentCoverDynamicScale,
        aspectRatio > 1 && styles.isWide,
        aspectRatio < 1 && styles.isNarrow,
        layoutClassName
      )}
    >
      <ImageCoverBase
        sanityConfig={sanity.clients.readOnly}
        imgProps={{ ...imgProps, alt: image?.alt, loading }}
        {...{ image, sizes, aspectRatio }}
      />
    </div>
  )
}

