import { Icon } from '/features/buildingBlocks/Icon'
import { animated } from 'react-spring'

import playIcon from '/images/icons/play.raw.svg'

import styles from './Cursor.css'

export function Cursor({ animation = undefined, cursor = 'default', layoutClassName = undefined }) {
  const Base = animation ? animated.div : 'div'

  return (
    <Base className={cx(styles.component, layoutClassName)} style={{ ...animation, cursor }}>
      <Icon icon={playIcon} layoutClassName={styles.iconLayout} />
    </Base>
  )
}
