import ReactPlayer from 'react-player'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { Icon } from '/features/buildingBlocks/Icon'
import { animated, useSpring, useSpringValue } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ImageCoverDynamicScale } from './Image'
import { HeadingXxs, HeadingXxxs } from './Heading'
import { useEvent } from '/machinery/useEvent'
import { Cursor } from './Cursor'

import iconAudio from '/images/icons/audio.raw.svg'
import iconPlay from '/images/icons/play-small.raw.svg'
import iconPause from '/images/icons/pause-small.raw.svg'
import iconFullscreen from '/images/icons/fullscreen.raw.svg'
import iconMinimize from '/images/icons/minimize.raw.svg'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './Video.css'

const disableVimeoControlsProp = {
  vimeo: {
    playerOptions: {
      loop: true,
      controls: false,
      background: true,
      playsinline: true
    }
  }
}

export function VideoWithContext({
  url,
  title,
  trackingTitle,
  poster,
  posterSizes = undefined,
  description = undefined,
  layoutClassName = undefined
}) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportMd)
  const [playing, setPlaying] = React.useState(false)
  const [hasProgress, setHasProgress] = React.useState(false)

  const textAnimation = useSpring({
    opacity: playing ? 0 : 1,
    y: playing ? 5 : 0
  })

  const cursorAnimation = useSpring({
    backdropFilter: `blur(${playing ? 0 : 25}px)`,
    opacity: playing ? 0 : 1,
  })

  const Heading = isViewportSm
    ? HeadingXxs
    : HeadingXxxs

  return (
    <VideoBase
      isPlaying={playing}
      onPlayChange={setPlaying}
      onTimeChange={setHasProgress}
      className={cx(styles.componentWithContext, playing && styles.isPlaying)}
      title={trackingTitle}
      {...{ poster, url, posterSizes, layoutClassName }}
    >
      {!hasProgress && (
        <div
          className={styles.overlayWithContext}
          data-style-context={cssStyleContext.contextBlack}
        >
          <Cursor
            animation={cursorAnimation}
            cursor={playing ? 'default' : 'pointer'}
            layoutClassName={styles.cursorContainerLayout}
          />
          <animated.div className={styles.textWrapper} style={textAnimation}>
            {title && <Heading h={3} layoutClassName={styles.titleLayout} {...{ title }} />}
            {description && <Heading h={4} layoutClassName={styles.descriptionLayout} title={description} />}
          </animated.div>
        </div>
      )}
    </VideoBase>
  )
}

export const VideoCover = React.forwardRef(VideoCoverImpl)
function VideoCoverImpl({
  url,
  title,
  poster,
  isPlaying,
  aspectRatio,
  onPlayChange,
  loop = true,
  muted = true,
  autoPlay = true,
  posterSizes = undefined,
  layoutClassName = undefined
}, ref) {
  const videoPlayerProps = { loop, muted, autoPlay }

  return (
    <VideoBase
      loop
      controls={false}
      className={styles.componentCover}
      playerClassName={styles.jaggedEdgesFix}
      {...{ ref, isPlaying, onPlayChange, poster, aspectRatio, title, url, posterSizes, layoutClassName, ...videoPlayerProps }}
    />
  )
}

export const VideoExternalControls = React.forwardRef(VideoExternalControlsImpl)
function VideoExternalControlsImpl({
  url,
  title,
  poster,
  isPlaying,
  onPlayChange,
  muted,
  onHoverChange = undefined,
  posterSizes = undefined,
  autoPlayWhenInView = false,
  layoutClassName = undefined
}, ref) {
  return (
    <VideoBase
      className={styles.componentExternalControls}
      {...{ ref, autoPlayWhenInView, poster, title, url, isPlaying, onPlayChange, onHoverChange, posterSizes, muted, layoutClassName }}
    />
  )
}

export function Video({
  url,
  title,
  poster,
  posterSizes = undefined,
  layoutClassName = undefined
}) {
  const [playing, setPlaying] = React.useState(false)
  const [hasProgress, setHasProgress] = React.useState(false)

  const animation =  useSpring({
    backdropFilter: `blur(${playing ? 0 : 25}px)`,
    opacity: playing ? 0 : 1,
  })

  return (
    <VideoBase
      isPlaying={playing}
      onPlayChange={setPlaying}
      onTimeChange={setHasProgress}
      className={styles.component}
      {...{ poster, title, url, posterSizes, layoutClassName }}
    >
      {!hasProgress && (
        <animated.div
          className={styles.overlay}
          data-style-context={cssStyleContext.contextBlack}
        >
          <Cursor
            cursor={playing ? 'default' : 'pointer'}
            layoutClassName={styles.cursorContainerLayout}
            {...{ animation }}
          />
        </animated.div>
      )}
    </VideoBase>
  )
}

const VideoBase = React.forwardRef(VideoBaseImpl)
function VideoBaseImpl({
  children = undefined,
  aspectRatio = 16 / 9,
  controls = undefined,
  autoPlay = false,
  muted: initialMuteValue = false,
  loop = false,
  poster,
  title,
  url,
  className,
  posterSizes,
  layoutClassName,
  isPlaying: playing,
  autoPlayWhenInView = true,
  onPlayChange: setPlaying,
  onTimeChange = undefined,
  onHoverChange = undefined,
  playerClassName = undefined
}, ref) {
  const isMounted = useRenderOnMount()
  const isMobile = useMediaQuery(mediaStyles.viewportSm) === false

  const videoRef = React.useRef(null)
  const videoContainerRef = React.useRef(null)
  const durationRef = React.useRef(0)
  const currentTime = videoRef?.current?.getCurrentTime()
  const hideClickToPlay = playing || currentTime > 0

  let timeoutId = null

  const [isHovering, setIsHovering] = React.useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false)
  const [isMuted, setIsMuted] = React.useState(initialMuteValue)
  const [isFullscreen, setFullscreen] = React.useState(false)
  const [progress, setProgress] = React.useState(0)

  const style = useSpring({
    display: isVideoLoaded ? 'block' : 'none',
    opacity: isVideoLoaded ? 1 : 0
  })

  const [posterStyle] = useSpring(
    () => ({ opacity: (isVideoLoaded && (playing || currentTime > 0)) ? 0 : 1 }),
    [isVideoLoaded, playing]
  )

  React.useEffect(
    () => {
      document.addEventListener('fullscreenchange', onFullscreenChange)

      return () => document.removeEventListener('fullscreenchange', onFullscreenChange)

      function onFullscreenChange() {
        setFullscreen(document.fullscreenElement !== null)
      }
    }, []
  )

  React.useEffect(() => {
    if (!onTimeChange || !currentTime) return

    onTimeChange(currentTime > 0)
  }, [currentTime])

  const hasControls = controls ?? playing

  const { ref: isInViewportRef } = useVisibility({
    enabled: autoPlayWhenInView,
    onVisible: handleVisible,
    onInvisible: handleInvisible,
  })

  React.useImperativeHandle(ref, () => ({
    playFromStart: () => videoRef.current.seekTo(0, 'seconds'),
    unmute: () => setIsMuted(false)
  }))

  const onHandleProgress = React.useCallback(handleProgress, [])
  const onHandleDuration = React.useCallback(handleDuration, [])
  const onMouseMoveEvent = React.useCallback(handleMouseMove, [])

  return (
    <div
      ref={videoContainerRef}
      onMouseMove={onMouseMoveEvent}
      onClick={handlePlayingState}
      className={cx(styles.component_rootBase, styles.componentBase, hideClickToPlay && styles.hidePointerCursor, className, layoutClassName)}
    >
      <div
        ref={isInViewportRef}
        className={styles.children}
      >
        {children}
      </div>

      {poster?.asset?._ref && (
        <animated.div style={posterStyle} className={styles.posterLayout}>
          <ImageCoverDynamicScale
            image={poster}
            sizes={posterSizes}
            layoutClassName={styles.posterLayout}
            {...{ aspectRatio }}
          />
        </animated.div>
      )}

      {isMounted && (
        <animated.div className={styles.player} {...{ style }}>
          <ReactPlayer
            playsinline
            ref={videoRef}
            width="100%"
            height="100%"
            muted={isMuted}
            onProgress={onHandleProgress}
            onDuration={onHandleDuration}
            onReady={() => setIsVideoLoaded(true)}
            onSeek={() => handleVideoTracking({ action: 'seek', type: 'click' })}
            onEnded={() => handleVideoTracking({ action: 'finished', type: 'ended' })}
            onPause={() => setPlaying(false)}
            // eslint-disable-next-line @kaliber/layout-class-name
            className={cx(styles.player, playing && styles.isPlaying, playerClassName)}
            progressInterval={100}
            controls={hasControls}
            config={{
              file: { attributes: { preload: 'metadata' }},
              ...hasControls ? disableVimeoControlsProp : {}
            }}
            {...(playing ? { loop } : {})}
            {...{ playing, url }}
          />
        </animated.div>
      )}

      <Controls
        layoutClassName={cx(styles.controlsLayout, isFullscreen && styles.fullscreenControlsLayout)}
        {...{
          progress,
          playing,
          setPlaying,
          isHovering,
          handleSeek,
          durationRef,
          isFullscreen,
          isMuted,
          children,
          handleFullScreen,
          handleMuteState,
          currentTime
        }}
      />

    </div>
  )

  function handleFullScreen() {
    if (!isFullscreen) {
      if (isMobile) {
        videoRef?.current?.getInternalPlayer().requestFullscreen()
      } else {
        if (videoContainerRef?.current?.requestFullscreen) {
          videoContainerRef?.current?.requestFullscreen()
            .then(() => {
              handleVideoTracking({ action: 'fullscreen', type: 'click' })
            })
        } else {
          videoContainerRef?.current?.webkitEnterFullscreen()
        }
      }
    } else {
      document?.exitFullscreen()
    }
  }

  function handleMouseMove() {
    handleHoverState(true)
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => handleHoverState(false), 2500)
  }

  function handleHoverState(x) {
    onHoverChange && onHoverChange(x)
    setIsHovering(x)
  }

  function handleSeek({ seconds }) {
    videoRef.current.seekTo(seconds, 'seconds')
  }

  function handleProgress(x) {
    setProgress(x.playedSeconds)
  }

  function handleDuration(x) {
    durationRef.current = x
  }

  function handleMuteState() {
    setIsMuted(!isMuted)
    handleVideoTracking({ action: isMuted ? 'unmute' : 'mute', type: 'click' })
  }

  function handlePlayingState() {
    if (currentTime > 0) return

    if (!playing) setPlaying(true)
    handleVideoTracking({ action: 'play', type: 'click' })
  }

  function handleVideoTracking({ action, type }) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackInteraction({
      title: 'video',
      action,
      type,
      index: 1,
      extraMetaData: {
        media: {
          title,
          duration: Number(duration).toFixed(2),
          progress: Number(progress).toFixed(2),
        }
      }
    })
  }

  function handleVisible() {
    if (autoPlay) {
      setPlaying(true)
    }
  }

  function handleInvisible() {
    if (playing) {
      setPlaying(false)
      handleVideoTracking({ action: 'scrolled out of viewport', type: 'scroll' })
    }
  }
}

function Controls({
  playing,
  setPlaying,
  isHovering,
  isFullscreen,
  handleSeek,
  durationRef,
  handleFullScreen,
  handleMuteState,
  isMuted,
  currentTime,
  progress,
  layoutClassName = undefined,
}) {

  const showControls = playing && isHovering || currentTime > 0 && isHovering

  const style = useSpring({
    opacity: showControls ? 1 : 0,
    y: showControls ? 0 : 5,
  })

  return (
      <animated.div style={style} className={cx(styles.componentControls, layoutClassName)}>
        <Button
          icon={playing ? iconPause : iconPlay}
          onClick={() => setPlaying(!playing)}
          lowerOpacity={!playing}
          layoutClassName={styles.buttonLayout}
        />

        <ProgressBar
          onSeek={handleSeek}
          duration={durationRef.current}
          layoutClassName={styles.progressBarLayout}
          {...{ progress }}
        />

        <Button
          icon={iconAudio}
          onClick={handleMuteState}
          lowerOpacity={isMuted}
          layoutClassName={styles.buttonLayout}
        />

        <Button
          icon={isFullscreen ? iconMinimize : iconFullscreen}
          onClick={handleFullScreen}
          layoutClassName={styles.buttonLayout}
        />
      </animated.div>
  )

  function Button({ icon, onClick, lowerOpacity = undefined, layoutClassName = undefined }) {
    return (
      <button
        className={cx(styles.componentButton, lowerOpacity && styles.lowerOpacity, layoutClassName)}
        {...{ onClick }}
      >
        <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
      </button>
    )
  }
}

function useVisibility({ enabled, onVisible, onInvisible }) {
  const { ref, isInViewport } = useIsInViewport({ rootMargin: '-10%' })

  React.useEffect(
    () => {
      if (enabled) {
        isInViewport
          ? onVisible()
          : onInvisible()
      }
    },
    [enabled, isInViewport, onVisible, onInvisible]
  )

  return { ref }
}

function ProgressBar({ duration, onSeek, progress, layoutClassName = undefined }) {
  const onSeekEvent = useEvent(handleChange)

  return (
    <input
      type='range'
      value={progress}
      min={0}
      max={duration}
      step='.001'
      className={cx(styles.progress, layoutClassName)}
      onChange={onSeekEvent}
    />
  )

  function handleChange(x) {
    onSeek({ seconds: x.currentTarget.value})
  }
}
